<script>
export default {
  mounted() {
    const ifr = this.$refs.jotform

    if (window.location.href && window.location.href.includes('?')) {
      const get = window.location.href.substr(window.location.href.indexOf('?') + 1)
      if (ifr && get.length > 0) {
        let src = ifr.src
        src = src.includes('?') ? src + '&' + get : src + '?' + get
        ifr.src = src
      }
    }
    const handleIFrameMessage = function (e) {
      if (typeof e.data === 'object') {
        return
      }
      const args = e.data.split(':')
      const iframe = ifr

      if (!iframe) {
        return
      }
      let src, script

      switch (args[0]) {
        case 'scrollIntoView':
          iframe.scrollIntoView()
          break
        case 'setHeight':
          iframe.style.height = args[1] + 'px'
          break
        case 'collapseErrorPage':
          if (iframe.clientHeight > window.innerHeight) {
            iframe.style.height = window.innerHeight + 'px'
          }
          break
        case 'reloadPage':
          window.location.reload()
          break
        case 'loadScript':
          src = args[1]
          if (args.length > 3) {
            src = args[1] + ':' + args[2]
          }
          script = document.createElement('script')
          script.src = src
          script.type = 'text/javascript'
          document.body.appendChild(script)
          break
        case 'exitFullscreen':
          if (window.document.exitFullscreen) window.document.exitFullscreen()
          else if (window.document.mozCancelFullScreen) window.document.mozCancelFullScreen()
          else if (window.document.mozCancelFullscreen) window.document.mozCancelFullScreen()
          else if (window.document.webkitExitFullscreen) window.document.webkitExitFullscreen()
          else if (window.document.msExitFullscreen) window.document.msExitFullscreen()
          break
      }
      const isJotForm = !!e.origin.includes('jotform')
      if (isJotForm && 'contentWindow' in iframe && 'postMessage' in iframe.contentWindow) {
        const urls = { docurl: encodeURIComponent(document.URL), referrer: encodeURIComponent(document.referrer) }
        iframe.contentWindow.postMessage(JSON.stringify({ type: 'urls', value: urls }), '*')
      }
    }
    if (window.addEventListener) {
      window.addEventListener('message', handleIFrameMessage, false)
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', handleIFrameMessage)
    }
  }
}
</script>
