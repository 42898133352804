<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.smAndDown"
    v-bind="$attrs"
    max-width="550"
    persistent
    scrollable
    v-on="$listeners"
  >
    <v-card>
      <v-card-title>
        Contact Us
        <v-spacer />
        <v-btn icon @click="$emit('input', false)">
          <v-icon>{{ iconClose }} </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <contact-form class="mx-auto" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ContactForm from '@/components/ContactForm'
import { mdiClose } from '@mdi/js'
export default {
  components: {
    ContactForm
  },

  data: () => ({
    iconClose: mdiClose
  })
}
</script>
