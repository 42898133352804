<template>
  <div class="text-left d-flex flex-column justify-center">
    <v-row justify="space-around" no-gutters>
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 5">
        <ul>
          <li v-for="(feature, index) in leftColumn" :key="index" class="xmr-5 mb-8 d-flex align-top">
            <v-avatar size="20" class="mt-2 mr-2 er-mark">
              <v-img :src="ErMark" alt="Epic Receptionists" />
            </v-avatar>
            <div>
              <span class="text-h6">{{ feature.title }}</span>
              <br />
              {{ feature.summary }}
            </div>
          </li>
        </ul>
      </v-col>

      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 5">
        <ul>
          <li v-for="(feature, index) in rightColumn" :key="index" class="xmr-5 mb-8 d-flex align-top">
            <v-avatar size="20" class="mt-2 mr-2 er-mark">
              <v-img :src="ErMark" alt="Epic Receptionists" />
            </v-avatar>
            <div>
              <span class="text-h6">{{ feature.title }}</span>
              <br />
              {{ feature.summary }}
            </div>
          </li>
        </ul>
      </v-col>
    </v-row>

    <div v-if="!hideCta" class="text-center mt-4">
      <div>Isn't it time you got some help?</div>
      <cta-btns color-sub-cta="black" xhide-sub-cta class="mx-auto" />
    </div>
  </div>
</template>

<script>
import { take, takeRight } from 'lodash-es'
import ErMark from '@/assets/mark-variations/Asset 5.svg'
import CtaBtns from '@/components/CtaButtons'

export default {
  components: {
    CtaBtns
  },
  props: {
    showAll: {
      type: Boolean,
      default: false
    },
    hideCta: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    features: [],
    ErMark
  }),

  computed: {
    leftColumn() {
      return take(this.features, this.features.length / 2)
    },
    rightColumn() {
      return takeRight(this.features, this.features.length / 2)
    }
  },

  async mounted() {
    const limit = this.showAll ? 100 : 6
    const features = await this.$content('features')
      .only(['title', 'summary', 'slug'])
      // sort by file name
      .sortBy('slug', 'asc')
      .limit(limit)
      .fetch()
    this.features = features
  }
}
</script>

<style scoped lang="scss">
.er-mark {
  transform: rotateZ(45deg);
}
</style>
