export default [
  {
    id: 'plan-1',
    name: 'Explorer',
    minutes: 'Pay per minute',
    callCountEstimate: false,
    cost: 3.5,
    units: 'minute',
    isFixedRate: true,
    points: ['Does not include a free trial', 'Includes all features', 'Minimum of $20/month']
  },
  {
    id: 'plan-2',
    name: 'Starter',
    minutes: '60 Minutes',
    callCountEstimate: '20-30',
    cost: 120,
    units: 'month',
    points: ['Comes with a 7 day trial', 'Includes all features', 'Overage rate of $2.00/minute']
  },
  {
    id: 'plan-3',
    name: 'Entrepreneur',
    minutes: '210 Minutes',
    callCountEstimate: '100-120',
    cost: 320,
    units: 'month',
    points: ['Comes with a 7 day trial', 'Includes all features', 'Overage rate of $1.75/minute']
  },
  {
    id: 'plan-4',
    name: 'Established',
    minutes: '360 Minutes',
    callCountEstimate: '180-200',
    cost: 480,
    isPopular: true,
    units: 'month',
    points: ['Comes with a 7 day trial', 'Includes all features', 'Overage rate of $1.50/minute']
  },
  {
    id: 'plan-5',
    name: 'No Name 1',
    minutes: '510 Minutes',
    callCountEstimate: '240-260',
    cost: 650,
    units: 'month',
    points: ['Comes with a 7 day trial', 'Includes all features', 'Overage rate of $1.33/minute']
  },
  {
    id: 'plan-6',
    name: 'No Name 2',
    minutes: '720 Minutes',
    callCountEstimate: '340-360',
    cost: 820,
    units: 'month',
    points: ['Comes with a 7 day trial', 'Includes all features', 'Overage rate of $1.30/minute']
  },
  {
    id: 'plan-7',
    name: 'No Name 3',
    minutes: '900 Minutes',
    callCountEstimate: '420-450',
    cost: 900,
    units: 'month',
    points: ['Comes with a 7 day trial', 'Includes all features', 'Overage rate of $1.20/minute']
  },
  {
    id: 'plan-8',
    name: 'Growth',
    minutes: '1200 Minutes',
    callCountEstimate: '550-600',
    cost: 1080,
    units: 'month',
    points: ['Comes with a 7 day trial', 'Includes all features', 'Overage rate of $1.00/minute']
  }
  // {
  //   id: 'plan-9',
  //   name: 'Custom',
  //   minutes: '1200 Minutes',
  //   callCountEstimate: 'unknown',
  //   cost: '?',
  //   isFixedRate: true,
  //   units: 'month',
  //   points: ['Comes with a 7 day trial', 'Includes all features', 'Overage rate of $1.00/minute']
  // }
]
