<template>
  <v-row justify="center">
    <v-col v-for="(details, index) in plans" :key="index" cols="5" :md="12 / plans.length">
      <v-card class="d-flex flex-column py-2" xcolor="secondary" to="pricing" height="80" outlined>
        <!-- <div class="text-caption">{{ details.name }}</div> -->
        <div class="text-h6 xmt-n2">{{ details.minutes }}</div>
        <div class="text-subtitle-1" :xclass="{ 'mt-6': !details.callCountEstimate }">
          {{ price(details) }}/{{ details.units }}
        </div>
        <div v-if="details.isPopular" class="ribbon"><span>popular</span></div>
        <!-- <div v-if="details.callCountEstimate" class="text-caption">
          About {{ details.callCountEstimate }} calls per month<span class="text-superscript">1</span>
        </div> -->
      </v-card>
    </v-col>

    <v-col cols="12" class="mt-4">
      <div class="mx-auto">
        Ready to get started?
        <cta-btns x-large color-sub-cta="black" xhide-sub-cta color="secondary" class="mx-auto" />
      </div>
    </v-col>

    <!-- <v-col md="4" offset-md="4">
      <p class="text-caption mt-6 text-center">
        (1) Estimate is based off an average appointment scheduling call duration of 90 seconds and non-scheduling call
        of 60 seconds
      </p>
    </v-col> -->
  </v-row>
</template>

<script>
import plans from '@/content/pricing-plans.js'
import CtaBtns from '@/components/CtaButtons'
import numeral from 'numeral'

export default {
  components: {
    CtaBtns
  },
  data: () => ({
    plans
  }),

  methods: {
    price(details) {
      // const price = details.isFixedRate ? details.cost : details.cost // - details.cost * (billingFrequency.discount / 100)
      return numeral(details.cost).format('$0,0[.]00')
    }
  }
}
</script>

<style scoped lang="scss">
.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;

  span {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #79a70a;
    background: linear-gradient(#f79e05 0%, #f79e05 100%);
    box-shadow: 0 1px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    right: -21px;

    &::before {
      content: '';
      position: absolute;
      left: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid #f79e05;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #f79e05;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid transparent;
      border-right: 3px solid #f79e05;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #f79e05;
    }
  }
}
</style>
