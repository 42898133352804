<template>
  <div class="form-contanct-us-wrapper" :class="{ 'is-mobile': $vuetify.breakpoint.xs }">
    <iframe
      id="JotFormIFrame-203096526759162"
      ref="jotform"
      title="General Inquiry Contact Form"
      allowtransparency="true"
      allowfullscreen="false"
      src="https://form.jotform.com/203096526759162"
      frameborder="0"
      style="min-width: 100%; min-height: 560px; border: none"
      scrolling="no"
    >
    </iframe>
  </div>
</template>

<script>
import JotFormMixin from '@/mixins/jotform'
export default {
  mixins: [JotFormMixin]
}
</script>

<style scoped lang="scss">
.form-contanct-us-wrapper {
  width: 480px;

  &.is-mobile {
    width: 100%;
  }
}
</style>
