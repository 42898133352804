<template>
  <v-container fluid class="pa-0">
    <hero-banner class="hero" />
    <!-- short explination section -->
    <!-- <v-container class="py-3 text-center">
      <h3 class="mb-6">
        We know you can't be everywhere.
        <br />
        Let us help.
      </h3>

      <p>
        Allow <span class="brand-name">Epic Receptionists</span> to answer your small business phones when you can't.
        <br />
        Our dedicated, trained receptionists are here to create a seamless, warm experience for your customers.
        <br />
        We care about small business owners and offer a personalized touch to our clients.
      </p>
    </v-container> -->

    <!-- summary of features -->
    <v-container class="py-6 text-center">
      <h2 class="text-h3">What Is Included</h2>
      <nuxt-link to="features" class="caption">See our Features page for full details</nuxt-link>
      <feature-summary show-all class="mt-6" />
    </v-container>

    <!-- "quick hits" section -->
    <v-container fluid class="section-highlight my-10">
      <v-container class="white--text">
        <quick-hits hide-cta flat />
      </v-container>
    </v-container>

    <!-- summary of pricing plans -->
    <v-container class="py-3 text-center">
      <h2 class="text-h3">Plans &amp; Pricing</h2>
      <nuxt-link to="pricing" class="caption">See our Pricing page for full details</nuxt-link>
      <pricing-summary class="mt-6 mb-10" />
    </v-container>

    <!-- "mega footer" section -->
    <v-container fluid class="section-highlight gradient" :class="{ 'gradient-dark': $vuetify.theme.dark }">
      <v-container>
        <mega-footer hide-background />
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import { mdiInstagram, mdiFacebook } from '@mdi/js'
import HeroBanner from '@/components/HeroBanner'
import FeatureSummary from '@/components/FeaturesSummary'
import QuickHits from '@/components/QuickHitsCta'
import PricingSummary from '@/components/PricingSummary'
import MegaFooter from '@/components/MegaFooter'

export default {
  // layout: 'pre-launch',

  components: {
    HeroBanner,
    FeatureSummary,
    QuickHits,
    PricingSummary,
    MegaFooter
  },

  data() {
    return {
      pageTitle: 'Live US-based Receptionist Service',
      pageDescription:
        'Never miss another business call again. We will answer every call and book appointments. Let us handle the phone calls so you can handle your business.',
      iconInstagram: mdiInstagram,
      iconFb: mdiFacebook
    }
  },

  head() {
    return {
      title: this.pageTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageDescription
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles.scss';

.hero {
  border-top: 1px solid #055852; //#353535;
}

.section-highlight {
  background-color: $primary;
  // min-height: 250px;

  &.gradient {
    @include bg-gradient;
  }

  &.gradient-dark {
    @include bg-gradient-dark;
  }
}
</style>
