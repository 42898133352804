<template>
  <div class="hero-wrapper">
    <v-container class="hero-container fill-height d-flex justify-center">
      <div class="text-section">
        <h1 class="d-flex flex-column catch-phrase-copy text-h3" :class="{ 'text-center': $vuetify.breakpoint.xs }">
          <span>Let us handle the phone calls</span>
          <span>so you can <span class="accent-text">handle your business</span> </span>
        </h1>
        <p class="white--text mt-4">
          Never miss another business call again.
          <br />
          <!-- Your receptionist team will save you time by answering calls and scheduling appointments. -->
          We will answer every call and book appointments.
          <br />
          Let us give you the time back to focus on growing your business.
        </p>
        <div class="mt-16">
          <cta-btns x-large />
        </div>
      </div>

      <v-img
        class="agent-img"
        :class="agentImage"
        contain
        position="bottom center"
        :laz-src="require(`@/assets/agent-headshots/${agentImage}.png?lqip`)"
        :src="require(`@/assets/agent-headshots/${agentImage}.png`)"
        alt="Epic Receptionists, agent headshot"
      />
    </v-container>
  </div>
</template>

<script>
import { random } from 'lodash-es'
import CtaBtns from '@/components/CtaButtons'
import GtmMixin from '@/mixins/gtm-info'
const number = random(1, 4)

export default {
  components: {
    CtaBtns
  },

  mixins: [GtmMixin],

  computed: {
    agentImage() {
      return `agent-${number}`
    }
  }

  // mounted() {
  //   const info = this.gtmInfo
  //   info.pageUrl = `${info.pageUrl}#${this.agentImage}`
  //   info.pageType = 'PageView'
  //   this.$gtm.push({ event: 'nuxtRoute', ...this.gtmInfo })
  // }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles.scss';
@import '~vuetify/src/styles/styles.sass';

.hero-wrapper {
  background-color: $background-dark;
  height: 90vh;
  max-height: 650px;
  overflow: hidden;

  .hero-container {
    position: relative;
  }
}

.text-section {
  position: absolute;
  z-index: 1;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .text-section {
    // transform: translateX(-85px);
    left: 125px;
  }
}

.agent-img {
  position: absolute;
  bottom: 0;
  left: 52%;
  height: 100%;
  z-index: 0;

  &.agent-1 {
    // white man looking forward
    left: 64%;
  }

  &.agent-2 {
    // black woman, facing worard
    left: 70%;
    height: 90%;
  }

  &.agent-3 {
    // illustration, female
    height: 85%;
    width: 100%;
    left: 30%;
  }

  &.agent-4 {
    // illustration, male
    height: 85%;
    width: 100%;
    left: 38%;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .agent-img {
    // opacity: 0.2;
    opacity: 0.6;
    // left: -140px;
    left: auto !important;
    // right: -100px;
  }

  .text-section {
    // transform: translateX(0);
    left: 5px;
    right: 5px;
  }
}

.catch-phrase-copy {
  color: white;
  font-weight: 600;
}

.accent-text {
  color: $accent;
}
</style>
