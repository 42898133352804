<template>
  <v-card
    v-intersect="onIntersect"
    flat
    tile
    class="mega-footer-wrapper mb-16"
    :class="{ 'has-background': !hideBackground }"
    min-height="200"
  >
    <v-row justify="space-around" align="stretch" class="fill-height">
      <!-- contact form -->
      <v-col cols="12" md="6" order-md="3">
        <v-card max-width="500px" class="mx-auto">
          <v-card-title> Have a question? Get in touch! </v-card-title>
          <v-divider />
          <contact-form v-if="isIntersecting" class="mx-auto" />
        </v-card>
      </v-col>

      <v-col cols="12" md="1" order-sm="2" class="text-center">
        <v-divider :vertical="$vuetify.breakpoint.mdAndUp" dark />
      </v-col>

      <!-- social links and copy -->
      <v-col cols="12" md="4" order-md="1" class="white--text">
        <p class="white--text">
          Allow <span class="brand-name">Epic Receptionists</span> to answer your small business phones when you can't.
          Our dedicated, trained receptionists are here to create a seamless, warm experience for your customers. We
          care about small business owners and offer a personalized touch to our clients.
        </p>
        <p>Isn't it time you got some help? Get in touch today start your free 1-week free trial</p>

        <div>
          <span class="font-weight-medium">Contact Details:</span>
          <ul>
            <li>
              Email:
              <a href="mailto:hello@epicreceptionists.com" class="white--text">hello&#64;epicreceptionists.com</a>
            </li>
            <li>Phone: <a href="tel:1-844-923-4321" class="white--text">1-844-923-4321</a></li>
          </ul>
        </div>

        <p class="mt-6 pt-5">
          <v-divider class="mb-5" />
          <span class="brand-name">Epic Receptionists</span> is a proud Michigan based company
          <v-avatar size="40">
            <v-img contain :src="iconMichigan" alt="The Mitten" />
          </v-avatar>
        </p>

        <div class="d-flex justify-space-between mt-12">
          <a href="http://instagram.com/epic.receptionists" target="_blank" rel="noopener">
            <span class="d-sr-only">Instagram</span>
            <v-icon x-large color="white">{{ iconInstagram }}</v-icon>
          </a>
          <a href="https://www.facebook.com/EpicReceptionists" target="_blank" rel="noopener">
            <span class="d-sr-only">Facebook</span>
            <v-icon x-large color="white">{{ iconFb }}</v-icon>
          </a>
          <a href="https://www.linkedin.com/company/epic-receptionists" target="_blank" rel="noopener">
            <span class="d-sr-only">LinedIn</span>
            <v-icon x-large color="white">{{ iconLinkedIn }}</v-icon>
          </a>

          <a href="https://twitter.com/epic_recepts" target="_blank" rel="noopener">
            <span class="d-sr-only">Twitter</span>
            <v-icon x-large color="white">{{ iconTwitter }}</v-icon>
          </a>
        </div>

        <!-- <v-btn
          x-large
          class="mt-5 mx-auto"
          :block="$vuetify.breakpoint.xs"
          elevation="10"
          @click="showContactForm = true"
        >
          <v-icon large left color="accent">
            {{ iconContactUs }}
          </v-icon>
          Contact Us
        </v-btn>

        <contact-dialog v-model="showContactForm" /> -->
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import iconMichigan from '@/assets/icon-michigan.svg'
import { mdiInstagram, mdiFacebook, mdiLinkedin, mdiTwitter, mdiEmailOutline } from '@mdi/js'
// import ContactDialog from '@/components/ContactUsDialog'
import ContactForm from '@/components/ContactForm'

export default {
  components: {
    // ContactDialog,
    ContactForm
  },

  props: {
    hideBackground: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    iconMichigan,
    iconTwitter: mdiTwitter,
    iconInstagram: mdiInstagram,
    iconFb: mdiFacebook,
    iconLinkedIn: mdiLinkedin,
    iconContactUs: mdiEmailOutline,
    showContactForm: false,
    isIntersecting: false
  }),

  methods: {
    onIntersect(entries, observer) {
      // More information about these options
      // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      this.isIntersecting = entries[0].isIntersecting
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles.scss';

.mega-footer-wrapper {
  background: transparent !important;
}

.has-background {
  @include bg-gradient;
}
</style>
