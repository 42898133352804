<template>
  <v-card color="primary" class="white--text pa-3" v-bind="$attrs">
    <div class="d-flex justify-space-around xalign-stretch mt-6">
      <div>
        <div class="mb-8">
          <v-icon color="white">{{ checkmark }}</v-icon>
          Real, live humans answer every call
        </div>

        <div class="mb-8">
          <v-icon color="white">{{ checkmark }}</v-icon>
          All receptionists are US-based
        </div>
      </div>

      <div>
        <div class="mb-8">
          <v-icon color="white">{{ checkmark }}</v-icon>
          No monthly contracts
        </div>

        <div class="mb-8">
          <v-icon color="white">{{ checkmark }}</v-icon>
          1-week free trial with all plans
        </div>
      </div>
    </div>

    <v-card-actions v-if="!hideCta" class="d-flex justify-center flex-column mt-5">
      <cta-btns large color="secondary" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mdiCheckBoxOutline } from '@mdi/js'
import CtaBtns from '@/components/CtaButtons'

export default {
  components: {
    CtaBtns
  },

  props: {
    hideCta: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    checkmark: mdiCheckBoxOutline
  }),

  computed: {
    cols() {
      return this.$vuetify.breakpoint.smAndDown ? 12 : 4
    }
  }
}
</script>
