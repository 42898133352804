<template>
  <div
    class="cta-btns d-flex flex-column justify-start align-stretch"
    :class="{ 'align-center': $vuetify.breakpoint.xs, 'align-start': $vuetify.breakpoint.smAndUp }"
  >
    <v-btn
      nuxt
      v-bind="$attrs"
      :to="{ name: 'sign-up' }"
      :color="color"
      class="black--text font-weight-bold"
      elevation="10"
      @click.stop.prevent="onClickSignUp"
    >
      Start your free trial today
    </v-btn>

    <v-btn v-if="!hideSubCta" class="mt-3" text :color="colorSubCta" @click="onClickContactUs">
      or contact us for more information
    </v-btn>

    <contact-dialog v-model="showContactUs" />
  </div>
</template>

<script>
import ContactDialog from '@/components/ContactUsDialog'
import GtmMixin from '@/mixins/gtm-info'

export default {
  components: {
    ContactDialog
  },

  mixins: [GtmMixin],

  props: {
    color: {
      type: String,
      default: () => 'accent'
    },

    colorSubCta: {
      type: String,
      default: () => 'white'
    },

    hideSubCta: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    showContactUs: false
  }),

  methods: {
    onClickContactUs() {
      this.showContactUs = true
      this.$gtm.push({ event: 'cta:contact-us', ...this.gtmInfo })
    },
    onClickSignUp() {
      this.$gtm.push({ event: 'cta:sign-up', ...this.gtmInfo })
      return false
    }
  }
}
</script>

<style scoped>
.cta-btns {
  max-width: 400px;
}
</style>
