export default {
  computed: {
    gtmInfo() {
      return {
        pageTitle: global.document.title,
        pageUrl: this.$route.fullPath,
        routeName: this.$route.name
      }
    }
  }
}
